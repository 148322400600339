import type {Dayjs} from 'dayjs';
import type {ActionCommand, ActionDecorator} from '~/lib/types/actionTypes';
import DateHelper from '~/utils/DateHelper';
import type {AvailableIcon} from '~/lib/types/IconTypes';

type dayjsDateType = string | number | Date | Dayjs;

/**
 * Get the Dutch text for the action repeat.
 * @param actionRepeat the action repeat enum
 * @returns the Dutch text
 * @throws Error if the actionRepeat parameter is invalid
 */
export const getActionRepeatText = (actionRepeat: ActionDecorator['repeat']): string => {
	switch (actionRepeat) {
		case 'MONTHLY':
			return 'Per maand';
		case 'QUARTERLY':
			return 'Per kwartaal';
		case 'YEARLY':
			return 'Per jaar';
		case 'NON_REPEATING':
			return 'Niet herhalen';
		default:
			throw new Error(`Invalid action repeat value ${actionRepeat}!`);
	}
};

export const addActionRepeat = (dateObject: dayjsDateType, actionRepeat: ActionDecorator['repeat'] | ActionCommand['repeat']): dayjsDateType => {
	switch (actionRepeat) {
		case 'MONTHLY':
			return DateHelper.addMonths(dateObject, 1);
		case 'QUARTERLY':
			return DateHelper.addMonths(dateObject, 3);
		case 'YEARLY':
			return DateHelper.addYears(dateObject, 1);
		case 'NON_REPEATING':
			return dateObject;
		default:
			throw new Error(`Invalid action repeat value ${actionRepeat}!`);
	}
};

export const getClonedRepeatedAction = (actionDecorator: ActionDecorator | undefined): ActionDecorator => {
	if (actionDecorator?.risk === undefined) {
		throw new Error('Could not clone action because action or risk was undefined!');
	}

	const clone: ActionDecorator = {...actionDecorator};

	clone.command.riskId = actionDecorator.risk.id;

	if (clone.startDate) {
		const addedStartDate = addActionRepeat(clone.startDate, clone.repeat);
		const formattedStartDate = DateHelper.getFormattedDate(addedStartDate, 'YYYY-MM-DD');
		clone.startDate = formattedStartDate;
		clone.command.startDate = formattedStartDate;
	}

	const addedEndDate = addActionRepeat(clone.endDate, clone.repeat);
	const formattedEndDate = DateHelper.getFormattedDate(addedEndDate, 'YYYY-MM-DD');
	clone.endDate = formattedEndDate;
	clone.command.endDate = formattedEndDate;

	return clone;
};

export const isActionClosedAndRepeated = (status: ActionDecorator['status'], repeat: ActionDecorator['repeat']) => {
	return status === 'CLOSED' && repeat !== 'NON_REPEATING';
};

/**
 * Check if the action is almost expired.
 * Number of days before the enddate that the action is considered almost expired is 14.
 * @param action
 */
export const actionIsAlmostExpired = (action: Pick<ActionDecorator, 'endDate'>) => {
	const daysBeforeEndDate = 14;
	return new Date(action.endDate) < new Date(new Date().getTime() + daysBeforeEndDate * 24 * 60 * 60 * 1000);
};

/**
 * Check if the action is expired, not counting today.
 * @param action
 */
export const actionIsPastEndDate = (action: Pick<ActionDecorator, 'endDate'>) => {
	return new Date(action.endDate) < new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
};

export const getProcessedActionStatus = (action: Pick<ActionDecorator, 'status' | 'endDate'>): {
	bgClass: 'success' | 'warning' | 'error' | 'neutral',
	text: string,
	icon: AvailableIcon | undefined,
	order: number
} => {
	if (action.status === 'CLOSED') {
		return {
			icon: 'check-circle',
			bgClass: 'success',
			text: 'Gereed',
			order: 0
		};
	}

	if (actionIsPastEndDate(action)) {
		return {
			icon: 'alert-circle',
			bgClass: 'error',
			text: 'Verlopen',
			order: 3
		};
	}

	if (actionIsAlmostExpired(action)) {
		return {
			icon: 'alert-circle',
			bgClass: 'warning',
			text: 'Bijna verlopen',
			order: 2
		};
	}

	return {
		icon: undefined,
		bgClass: 'neutral',
		text: 'Open',
		order: 1
	};
};
